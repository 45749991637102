<template>
  <!-- 代理佣金明细页面 -->
  <div class="positionT0L0">
    <div class="information">
      <div>
        {{ $route.params.realName }} | {{ $route.params.agentRegion }} |
        {{ $route.params.agentName }}
      </div>
      <div>加入时间 : {{ $route.params.createTime }}</div>
    </div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left"></div>
      <div class="top_rigiht">
        <span> 返佣时间 <a-range-picker @change="onChangeDate" /></span>
        <span
          >类型
          <a-select
            v-model="type"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="typeChange"
          >
            <a-select-option v-for="item in typeArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </span>
        <a-input
          v-model="searchVal"
          placeholder="请输入订单号"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <h2>累计返佣金额 : {{ $route.params.commissionSum }} 元</h2>
    <div>返佣金额非实时数据，次日6：00更新前一天的数据</div>
    <div style="margin-top: 20px">
      <a-button type="primary" @click="exportClick">导出数据</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
    >
    </a-table>
    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { AgentApi, AgentExportApi } from "@/request/api/marketingCenter";
export default {
  components: { MyPagination },
  created() {
    this.AgentFn();
  },
  data() {
    return {
      isDisableSearch: false, // 搜索的按钮是否禁用
      tableLoading: true, // 表格的loading图
      pageNo: 1,
      pageSize: 20,
      count: 0,
      startTime: "",
      endTime: "",
      searchVal: "",
      type: "", // 类型的默认值
      typeArr: [
        { key: "", value: "全部类型" },
        { key: "1", value: "线上" },
        { key: "2", value: "线下" },
      ], // 类型的数组
      columns: [
        {
          title: "关联订单号",
          dataIndex: "orderNo",
          key: "orderNo",
          scopedSlots: { customRender: "orderNo" },
        },
        {
          title: "类型",
          dataIndex: "orderType",
          key: "orderType",
          scopedSlots: { customRender: "orderType" },
        },
        {
          title: "返佣金额 (元)",
          dataIndex: "amount",
          key: "amount",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "返佣时间",
          dataIndex: "createTime",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
      ],
      tableData: [],
    };
  },
  methods: {
    // 类型改变的回调
    typeChange(value) {
      this.type = value;
      this.AgentFn();
    },
    // 搜索 - 修改时间
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.startTime = dateString[0] + " 00:00:00";
        this.endTime = dateString[1] + " 23:59:59";
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    // 回车和搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.AgentFn();
    },
    // 导出数据按钮
    exportClick() {
      window.location.href = `${AgentExportApi}?agentUserId=${this.$route.params.agentUserId}&pageNo=1&pageSize=20`;
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.AgentFn();
    },
    // 获取分润列表函数
    AgentFn() {
      AgentApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        agentUserId: this.$route.params.agentUserId, // 用户ID
        type: this.type, // 	类型 1:线上 2:线下
        startTime: this.startTime, // 开始时间
        endTime: this.endTime, // 结束时间
        keyword: this.searchVal, // 搜索关键字-订单号
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data;
          for (let i in newData) {
            newData[i].key = i;
          }
          this.tableData = newData;
          this.count = data.count;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.information {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  background-color: #ecf8fe;
  div {
    line-height: 40px;
    margin-left: 30px;
  }
}
.top {
  width: 70%;
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
      /deep/.ant-select {
        margin-left: 10px;
      }
    }
  }
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
/deep/.ant-table-wrapper {
  width: 70%;
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>